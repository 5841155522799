<template>
  <div class="allText">
    <div class="allText-btnGroup" v-if="isBuy == 1">
      <div
        class="btn-item"
        v-for="(item, index) in btnGroup"
        :key="index"
        @click="handleClick(item)"
      >
        <img :src="item.imgSrc" alt="" />
        <span>{{ item.title }}</span>
      </div>
    </div>
    <div class="allText-title">
      <div class="title-black" @click="hanleBlack">
        <i class="el-icon-arrow-left"></i>返回
      </div>
      <!-- <div class="title-text">溯源时间：2021-10-30 10:30:22；溯源库/时限：全库/2021-10-30前</div> -->
    </div>
    <div class="content" v-html="contentText"></div>
    <!-- <div class="column-mask"  style="display: flex;align-items: center;justify-content: center;margin: 30px 0;">
      <img src="../../../../assets/images/mask.png" alt="" style="width:12px;height:16px;margin-right:9px;">
      <div class="column-mask-text" style="font-size: 14px;font-family: PingFang SC;font-weight: 600;color: #ff6900;cursor: pointer;">剩余70%未读，购买即可解锁全文，<span style=" text-decoration: underline;">去购买</span></div>
    </div> -->
    <!-- 转存网盘 -->
    <el-dialog title="转存网盘" :visible.sync="movePopup" class="addFil">
      <el-tree
        :expand-on-click-node="false"
        style="180px"
        class="tree filter-tree"
        highlight-current
        accordion
        :indent="16"
        :data="TreeData"
        :auto-expand-parent="false"
        node-key="id"
        :props="defaultProps"
        @node-click="handleNodeClick"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>
            {{ test(node, data) }}
            <el-image
              :src="require(`@/assets/images/disk/Icons/${data.picture}.png`)"
              style="width: 30px; height: 30px; vertical-align: middle"
            ></el-image>
            {{ data.filename }}
          </span>
        </span>
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <button class="footer-btn sure" @click="handleSure">确 定</button>
        <button class="footer-btn" @click="movePopup = false">取 消</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAlltext, saveNetdisk, downloadFile } from "api/copyrightService";
import { directory } from "api/disk";
export default {
  data() {
    return {
      btnGroup: [
        {
          imgSrc: require("assets/images/alltext-download.png"),
          title: "下载",
          id: 1,
        },
        {
          imgSrc: require("assets/images/alltext-disk.png"),
          title: "转存网盘",
          id: 2,
        },
        {
          imgSrc: require("assets/images/alltext-share.png"),
          title: "分享",
          id: 3,
        },
        {
          imgSrc: require("assets/images/alltext-copyIcon.png"),
          title: "复制",
          id: 4,
        },
      ],
      id: "",
      fileName: "",
      contentText: "",
      //转存网盘弹窗
      movePopup: false,
      checkData: "",
      TreeData: [],
      moveid: "",
      defaultProps: {
        children: "children",
        label: "filename",
      },
      isBuy: null, //是否购买
    };
  },
  methods: {
    // 返回
    hanleBlack() {
      this.$router.go(-1);
    },
    // 点击事件
    handleClick(item) {
      if (item.id == 1) {
        this.download();
      } else if (item.id == 2) {
        this.moveTo();
      } else if (item.id == 3) {
        console.log("分享");
      } else if (item.id == 4) {
        this.copyAllText();
      }
    },
    // 复制
    copyAllText() {
      let domUrl = document.createElement("input"); //创建一个input
      domUrl.value = this.contentText;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        type: "success",
        message: "复制成功！",
      });
    },
    // 下载
    async download() {
      // const url = "https://fafs.antiplagiarize.com/";
      // window.location.href = `${url}${this.docmd5}`;
      let formData = new FormData();
      //  formData.append("id","f70621623bf540702c653de27300773a")
      formData.append("md5", this.id);
      const res = await downloadFile(formData);
      const blob = new Blob([res], {
        type: "application/octet-stream",
      });
      const fileName = this.fileName + ".docx";
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },
    // 获取全文
    async getAllFont() {
      let formData = new FormData();
      //  formData.append("id","f70621623bf540702c653de27300773a")
      formData.append("id", this.id);
      const res = await getAlltext(formData);
      if (res.code === 200) {
        this.contentText = res.data;
        this.isBuy = res.message;
      }
    },
    // 获取网盘列表
    async moveTo(checkData) {
      this.checkData = checkData;
      console.log(this.checkData);
      this.movePopup = true;
      const data = {
        id: 0,
      };
      const res = await directory(data);
      this.TreeData = res.data;
    },
    handleNodeClick(data) {
      this.moveid = data.id;
    },
    // 转存网盘确定
    async handleSure() {
      const formData = new FormData();
      formData.append("textMd5", this.id);
      formData.append("id", this.moveid);
      const res = await saveNetdisk(formData);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "转存成功！",
        });
        this.movePopup = false;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    test(node, data) {
      switch (data.filetype) {
        case 0:
          if (data.isDeleted == -1) {
            data.picture = "01";
          } else {
            data.picture = "03";
          }
          break;
      }
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.fileName = this.$route.query.fileName;
    if (this.id != "") {
      this.getAllFont();
    }
  },
};
</script>

<style lang="scss" scoped>
.allText {
  &-btnGroup {
    height: 54px;
    border-bottom: 1px solid #eceff1;
    padding-left: 22px;
    display: flex;
    .btn-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-right: 71px;
      cursor: pointer;
      img {
        width: 24.5px;
        height: 24.5px;
      }
      span {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-top: 3px;
      }
    }
  }
  &-title {
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .title-black {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 22px;
      cursor: pointer;
      font-size: 14px;
      font-family: PingFang SC;
      color: #333333;
    }
    .title-text {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .content {
    padding: 0 50px 30px;
    line-height: 30px;
  }
  .addFil {
    ::v-deep .el-dialog {
      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .footer-btn {
            width: 71px;
            height: 41px;
            border: 1px solid #cecece;
            border-radius: 3px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            background: #fff;
          }
          .sure {
            margin-right: 12px;
            background: #ff6900;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
